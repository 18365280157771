
import { all, takeLatest, takeEvery } from "redux-saga/effects";
import { SAGA_ACTIONS } from "../resources/constants";
import * as UserSaga from './user'
import * as BankTrans from './bankTrans'
import * as chartAccount from './chartAccount'
import * as glClassification from './glClassification'
import * as glBankFeed from './glBankFeed'
import * as glViewRegister from './glViewRegister'
import * as ReportSaga from './report'
import * as FourThreeThree from './fourThreeThreeB'
import * as Settings from './settings'
import * as Budget from './budget'


export default function* rootSaga() {
    yield all([
        // takeLatest(SAGA_ACTIONS.USER.LOGIN, UserSaga.login),
        takeLatest(SAGA_ACTIONS.USER.DETAILS, UserSaga.me),
        takeEvery(SAGA_ACTIONS.TRANSACTION.TRANS_LIST, BankTrans.bankTrans),
        takeLatest(SAGA_ACTIONS.TRANSACTION.CREATE_TRANSACTION_ADJUSTMENT, BankTrans.createAdjustment),
        takeLatest(SAGA_ACTIONS.USER.USER_ACCESS, UserSaga.user_access),
        // takeLatest(SAGA_ACTIONS.USER.ACCOUNT_ALLOWANCE, UserSaga.user_allowance),
        // takeLatest(SAGA_ACTIONS.USER.USER_STEP_STATUS, UserSaga.user_step_status),
        // takeEvery(SAGA_ACTIONS.USER.USER_STEP_STATUS, UserSaga.user_step_status),

        takeEvery(SAGA_ACTIONS.CHART_ACCOUNT.LIST, chartAccount.list),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.DETAILS, chartAccount.accountDetails),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.CREATE, chartAccount.create),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.UPDATE, chartAccount.update),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.AVAILABLE_ACCOUNT, chartAccount.availableAccounts),
        takeEvery(SAGA_ACTIONS.CHART_ACCOUNT.GL_ACCOUNT_LIST, chartAccount.gl_account_list),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.LIST_TREE, chartAccount.list_tree),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.ACCOUNT_LEBEL, chartAccount.lebel),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.BANK_SALE_LIST, chartAccount.bankAccountList),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.BANK_SALE_UPDATE, chartAccount.bankAccountUpdate),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.FETCH_OPENNING_BALANCE, chartAccount.fetchOpenningAcc),
        takeLatest(SAGA_ACTIONS.CHART_ACCOUNT.POST_OPENNING_BALANCE, chartAccount.postOpenningAcc),

        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.GL_TRANS_LIST, glClassification.list),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_LIST, glClassification.reconcileTxnList),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_OPENNING_BALANCE_FETCH, glClassification.reconcileOpenningBalanceFetch),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.GL_RECONCILE_TRANS_POST, glClassification.reconcilePost),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.GL_SALES_LIST, glClassification.saleList),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.BANK_GL_UPDATE, glClassification.bankGlUpdate),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.SALE_GL_UPDATE, glClassification.saleGlUpdate),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.REVENUE_GL_UPDATE, glClassification.revenueGlUpdate),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.CLASSIFICATION_STATUS, glClassification.classificationStatus),
        takeLatest(SAGA_ACTIONS.GL_CLASSIFICATION.COMPLETE_STEP, glClassification.completeStatus),

        takeLatest(SAGA_ACTIONS.BANK_FEED.FETCH_LIST, glBankFeed.list),
        takeLatest(SAGA_ACTIONS.BANK_FEED.UPDATE, glBankFeed.feedUpdate),

        takeLatest(SAGA_ACTIONS.VIEW_REGISTER.FETCH_LIST, glViewRegister.list),

        takeLatest(SAGA_ACTIONS.REPORT.BALANCE_SHEET, ReportSaga.balanceSheet),
        takeLatest(SAGA_ACTIONS.REPORT.REFRESH_BALANCE_SHEET, ReportSaga.balanceSheetRefresh),
        takeLatest(SAGA_ACTIONS.REPORT.REPORT_DOWNLOAD_BS, ReportSaga.reportDownload),
        takeLatest(SAGA_ACTIONS.REPORT.REPORT_DOWNLOAD_BT, ReportSaga.reportDownloadBT),
        takeLatest(SAGA_ACTIONS.REPORT.PROFIT_LOSSS, ReportSaga.profitLoss),
        takeLatest(SAGA_ACTIONS.REPORT.STATEMENT_OF_CASH_FLOW, ReportSaga.cashFlow),
        takeLatest(SAGA_ACTIONS.REPORT.DASHBOARD_RATIO, ReportSaga.dashboardRatio),

        takeLatest(SAGA_ACTIONS.FOUR_THREE_THREE_B.DETAILS, FourThreeThree.getData),
        takeLatest(SAGA_ACTIONS.FOUR_THREE_THREE_B.CREATE, FourThreeThree.create),
        takeLatest(SAGA_ACTIONS.FOUR_THREE_THREE_B.UPDATE, FourThreeThree.update),
        takeLatest(SAGA_ACTIONS.FOUR_THREE_THREE_B.MONTHLY_EXPENSE, FourThreeThree.getIncome),
        takeLatest(SAGA_ACTIONS.PERSONAL_BALANCE_SHEET.CREATE, FourThreeThree.createBS),
        takeLatest(SAGA_ACTIONS.PERSONAL_BALANCE_SHEET.DETAILS, FourThreeThree.fetchBS),
        takeLatest(SAGA_ACTIONS.PERSONAL_INCOME_STATMENT.CREATE, FourThreeThree.createPI),
        takeLatest(SAGA_ACTIONS.PERSONAL_INCOME_STATMENT.DETAILS, FourThreeThree.fetchPI),

        // takeLatest(SAGA_ACTIONS.SETTINGS.FETCH_DETAILS, Settings.fetchSettings),
        takeLatest(SAGA_ACTIONS.SETTINGS.UPDATE_ACCOUNTING, Settings.updateAccountingSettings),
        takeLatest(SAGA_ACTIONS.SETTINGS.UPDATE_SALES, Settings.updateSalesSettings),
        takeLatest(SAGA_ACTIONS.SETTINGS.FETCH_ACCOUNT_DETAILS, Settings.fetchAccountSettings),

        takeLatest(SAGA_ACTIONS.BUDGET.FETCH_DETAILS, Budget.fetchBudget),
        takeLatest(SAGA_ACTIONS.BUDGET.ADD, Budget.addBudget),
        
    ])
}