
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFourThreeThreeBApi } from '../../resources/hooks/api/fourThreeThreeBApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { MONTHS, STATES } from '../../resources/constants';
import { calculateMaxHeightContent, lastYears } from '../../resources/functions';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CreateFourThreeThreeFormI, PersonalBalanceFormI } from '../../resources/form-props';
import { PersonalBalanceValidate } from '../../resources/form-validator'
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import moment from 'moment'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';

import { getBranch } from '../../store/user/selectors'

interface Props {

}

const PersonalBalanceSheet: React.FC<Props> = () => {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const initData = {
        personal_balance_sheet_info_self: {
            salutation: 'Mr',
            first_name: '',
            last_name: '',
            initial: '',
            current_address: '',
            current_city: '',
            current_state: '',
            current_zip: '',
            current_country: '',
            current_social_security_number: '',
            current_date_of_birth: moment().format('MM-DD-YYYY'),
            current_residence: '',
            current_owner_type: '',
            current_years: new Date().getFullYear(),
            current_months: new Date().getMonth(),
            current_date: '',
            previous_address: '',
            previous_city: '',
            previous_state: '',
            previous_zip: '',
            previous_date: moment().format('MM-DD-YYYY'),
            previous_owner_type: '',
            previous_years: 0,
            previous_months: 0,
            previous_personal_phone: '',
            previous_work_phone: '',
            employer_name: '',
            employer_address: '',
            employer_city: '',
            employer_state: '',
            employer_zip: '',
            employer_position: '',
            employer_title: '',
            employer_service_yrs: 0,
            employer_service_months: 0,
            previous_employer_name: '',
            previous_employer_service_yrs: 0,
            previous_employer_service_months: 0,
            previous_employer_wk_phone: '',
            previous_employer_position: '',
            previous_employer_title: '',
            dependants: [{
                name: '',
                relationship: '',
            }],
        },
        personal_balance_sheet_info_spouse: {
            salutation: 'Mrs',
            first_name: '',
            last_name: '',
            initial: '',
            current_address: '',
            current_city: '',
            current_state: '',
            current_zip: '',
            current_country: '',
            current_social_security_number: '',
            current_date_of_birth: moment().format('MM-DD-YYYY'),
            current_residence: '',
            current_owner_type: '',
            current_years: new Date().getFullYear(),
            current_months: new Date().getMonth(),
            current_date: '',
            previous_address: '',
            previous_city: '',
            previous_state: '',
            previous_zip: '',
            previous_date: moment().format('MM-DD-YYYY'),
            previous_owner_type: '',
            previous_years: 0,
            previous_months: 0,
            previous_personal_phone: '',
            previous_work_phone: '',
            employer_name: '',
            employer_address: '',
            employer_city: '',
            employer_state: '',
            employer_zip: '',
            employer_position: '',
            employer_title: '',
            employer_service_yrs: 0,
            employer_service_months: 0,
            previous_employer_name: '',
            previous_employer_service_yrs: 0,
            previous_employer_service_months: 0,
            previous_employer_wk_phone: '',
            previous_employer_position: '',
            previous_employer_title: '',
            dependants: [{
                name: '',
                relationship: '',
            }],
        },
        balance_sheet_assets: [],
        balance_sheet_liabilities: [],
        personal_balance_sheet_annual_income: [],
        personal_balance_sheet_section_one_cash_type: [],
        personal_balance_sheet_section_one_retirement_type: [],
        personal_balance_sheet_section_one_certificate_type: [],
        personal_balance_sheet_section_two_equities: [],
        personal_balance_sheet_section_two_equities_loan: [],
        personal_balance_sheet_section_two_bonds: [],
        personal_balance_sheet_section_two_bonds_loan: [],
        personal_balance_sheet_section_two_securities: [],
        personal_balance_sheet_section_two_securities_loan: [],
        personal_balance_sheet_section_three_life_insurance: [],
        personal_balance_sheet_section_four_receivable_due: [],
        personal_balance_sheet_section_five_real_estate_primary_property: [],
        personal_balance_sheet_section_five_real_estate_other_property: [],
        section_six_profit_sharing: [],
        section_seven_automobiles: [],
        section_eight_personal_property: [],
        section_nine_notes: [],
        section_nine_other_notes: [],
        section_ten: [],
    }
    const branchData = useSelector(getBranch)
    const contentRef = useRef<HTMLDivElement>(null);
    const pagesRefs = useRef<(HTMLDivElement | null)[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const { getPersonalBalanceSheet, createPersonalBalanceSheet } = useFourThreeThreeBApi();
    const [currentMonthIndex, setCurrentMonthIndex] = useState<any>(new Date().getMonth());
    const currentMonthName = months[currentMonthIndex];
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useState<any>({
        month: { value: new Date().getMonth() + 1, label: currentMonthName },
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false)
    const [totalBSAssets, setTotalBSAssets] = useState<number>(0);
    const [totalBSLiabilities, setTotalBSLiabilities] = useState<number>(0);
    const [totalBSNetWorth, setTotalBSNetWorth] = useState<number>(0);
    const [totalAnnualIncomeSelf, setTotalAnnualIncomeSelf] = useState<number>(0);
    const [totalAnnualIncomeSpouse, setTotalAnnualIncomeSpouse] = useState<number>(0);

    const [totalSectionOneCashType, setTotalSectionOneCashType] = useState<number>(0);
    const [totalSectionOneRetiementType, setTotalSectionOneRetiementType] = useState<number>(0);
    const [totalSectionOneCertificateType, setTotalSectionOneCertificateType] = useState<number>(0);

    const [totalEquities, setTotalEquities] = useState<number>(0);
    const [totalEquitiesLoan, setTotalEquitiesLoan] = useState<number>(0);
    const [totalEquityNetWorth, setTotalEquityNetWorth] = useState<number>(0);

    const [totalBonds, setTotalBonds] = useState<number>(0);
    const [totalBondsLoan, setTotalBondsLoan] = useState<number>(0);
    const [totalBondsNetWorth, setTotalBondsNetWorth] = useState<number>(0);

    const [totalSecurity, setTotalSecurity] = useState<number>(0);
    const [totalSecurityLoan, setTotalSecurityLoan] = useState<number>(0);
    const [totalSecurityNetWorth, setTotalSecurityNetWorth] = useState<number>(0);

    const [totalInsuranceTerm, setTotalInsuranceTerm] = useState<number>(0);
    const [totalInsuranceWhole, setTotalInsuranceWhole] = useState<number>(0);
    const [totalInsuranceLoan, setTotalInsuranceLoan] = useState<number>(0);

    const [totalReceivableDue, setTotalReceivableDue] = useState<number>(0);

    const [totalRealEstatePrimaryMrkt, setTotalRealEstatePrimaryMrkt] = useState<number>(0);
    const [totalRealEstatePrimaryMortgage, setTotalRealEstatePrimaryMortgage] = useState<number>(0);
    const [totalRealEstateOtherMrkt, setTotalRealEstateOtherMrkt] = useState<number>(0);
    const [totalRealEstateOtherMortgage, setTotalRealEstateOtherMortgage] = useState<number>(0);

    const [totalPensionValue, setTotalPensionValue] = useState<number>(0);
    const [totalPensionVested, setTotalPensionVested] = useState<number>(0);
    const [totalPensionLoan, setTotalPensionLoan] = useState<number>(0);

    const [totalAutoMobileLoan, setTotalAutoMobileLoan] = useState<number>(0);
    const [totalAutoMobileAppraisal, setTotalAutoMobileAppraisal] = useState<number>(0);
    const [totalAutoMobileValue, setTotalAutoMobileValue] = useState<number>(0);

    const [totalPropertyLoan, setTotalPropertyLoan] = useState<number>(0);
    const [totalPropertyAppraisal, setTotalPropertyAppraisal] = useState<number>(0);
    const [totalPropertyValue, setTotalPropertyValue] = useState<number>(0);

    const [totalFinancial, setTotalFinancial] = useState<number>(0);
    const [totalLiabilities, setTotalLiabilities] = useState<number>(0);

    const [totalSectionTen, setTotalSectionTen] = useState<number>(0);

    useEffect(() => {
        setTotalBSNetWorth(totalBSAssets - totalBSLiabilities)
    }, [totalBSAssets, totalBSLiabilities])
    useEffect(() => {
        setTotalEquityNetWorth(totalEquities - totalEquitiesLoan)
    }, [totalEquities, totalEquitiesLoan])
    useEffect(() => {
        setTotalBondsNetWorth(totalBonds - totalBondsLoan)
    }, [totalBonds, totalBondsLoan])
    useEffect(() => {
        setTotalSecurityNetWorth(totalSecurity - totalSecurityLoan)
    }, [totalSecurity, totalSecurityLoan])

    const { control, register, reset, setValue, getValues, watch, handleSubmit, formState: { errors } } = useForm<PersonalBalanceFormI>({
        defaultValues: initData,
        resolver: yupResolver(PersonalBalanceValidate)
    });

    useEffect(() => {
        // reset(initData);
        // initialiseForm();
        fetchMonthlyIncome();
    }, [])

    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'IRA /401k/SEO/Certificate/Other (Section 1)');
        if (found[0]) {
            found[0].amount = totalSectionOneRetiementType + totalSectionOneCertificateType;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalSectionOneRetiementType, totalSectionOneCertificateType])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Equites/Bonds/Securities (Section 2)');
        if (found[0]) {
            found[0].amount = totalEquityNetWorth + totalBondsNetWorth + totalSecurityNetWorth;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalEquityNetWorth, totalBondsNetWorth, totalSecurityNetWorth])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Life Insurance Cash Value (Section 3)');
        if (found[0]) {
            found[0].amount = totalInsuranceTerm + totalInsuranceWhole;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalInsuranceTerm, totalInsuranceWhole])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Receivables / Contract Payments due to me (Section 4)');
        if (found[0]) {
            found[0].amount = totalReceivableDue;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalReceivableDue])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Primary Residence (Section 5)');
        if (found[0]) {
            found[0].amount = totalRealEstatePrimaryMrkt;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalRealEstatePrimaryMrkt])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Other Real Estate (Section 5)');
        if (found[0]) {
            found[0].amount = totalRealEstateOtherMrkt;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalRealEstateOtherMrkt])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Profit Sharing & Pension (Section 6 Vested)');
        if (found[0]) {
            found[0].amount = totalPensionLoan;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalPensionLoan])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Automobiles (Section 7)');
        if (found[0]) {
            found[0].amount = totalAutoMobileValue;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalAutoMobileValue])
    useEffect(() => {
        const found = balanceSheetAssetsFields.filter((item: any) => item['description'] === 'Personal Property (Section 8)');
        if (found[0]) {
            found[0].amount = totalPropertyAppraisal;
            setValue('balance_sheet_assets', balanceSheetAssetsFields);
            calculateTotalBSAssets();
        }
    }, [totalPropertyAppraisal])
    useEffect(() => {
        const found = balanceSheetLiabilitiesFields.filter((item: any) => item['description'] === 'Short Term Notes Due Financial Institutions (Section 9)');
        if (found[0]) {
            found[0].amount = totalFinancial;
            setValue('balance_sheet_liabilities', balanceSheetLiabilitiesFields);
            calculateTotalBSLiabilities()
        }
    }, [totalFinancial])
    useEffect(() => {
        const found = balanceSheetLiabilitiesFields.filter((item: any) => item['description'] === 'Other Short Term Liabilities/Notes Due (Section 9)');
        if (found[0]) {
            found[0].amount = totalLiabilities;
            setValue('balance_sheet_liabilities', balanceSheetLiabilitiesFields);
            calculateTotalBSLiabilities()
        }
    }, [totalLiabilities])
    useEffect(() => {
        const found = balanceSheetLiabilitiesFields.filter((item: any) => item['description'] === 'Credit Accounts and Bills Due (Section 10)');
        if (found[0]) {
            found[0].amount = totalSectionTen;
            setValue('balance_sheet_liabilities', balanceSheetLiabilitiesFields);
            calculateTotalBSLiabilities()
        }
    }, [totalSectionTen])
    useEffect(() => {
        const found = balanceSheetLiabilitiesFields.filter((item: any) => item['description'] === 'Insurance/Profit Sharing and Pension Loans (Section 3 and 6)');
        if (found[0]) {
            found[0].amount = totalInsuranceLoan + totalPensionLoan;
            setValue('balance_sheet_liabilities', balanceSheetLiabilitiesFields);
            calculateTotalBSLiabilities()
        }
    }, [totalInsuranceLoan, totalPensionLoan])
    useEffect(() => {
        const found = balanceSheetLiabilitiesFields.filter((item: any) => item['description'] === 'Mortgages on Primary Residence (Section 5)');
        if (found[0]) {
            found[0].amount = totalRealEstatePrimaryMortgage;
            setValue('balance_sheet_liabilities', balanceSheetLiabilitiesFields);
            calculateTotalBSLiabilities()
        }
    }, [totalRealEstatePrimaryMortgage])
    useEffect(() => {
        const found = balanceSheetLiabilitiesFields.filter((item: any) => item['description'] === 'Mortgages on Other Real Estate (Section5)');
        if (found[0]) {
            found[0].amount = totalRealEstateOtherMortgage;
            setValue('balance_sheet_liabilities', balanceSheetLiabilitiesFields);
            calculateTotalBSLiabilities()
        }
    }, [totalRealEstateOtherMortgage])

    const calculateTotalBSAssets = () => {
        balanceSheetAssetsFields.reduce(
            (acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0)
        setTotalBSAssets(balanceSheetAssetsFields.reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0));
    }
    const calculateTotalBSLiabilities = () => {
        setTotalBSLiabilities(balanceSheetLiabilitiesFields
            .reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0));
    }

    const initialiseForm = () => {
        addMoreBalanceSheetAssets([{
            description: 'Cash (Section 1)',
            amount: 0,
            read_only: false
        }, {
            description: 'IRA /401k/SEO/Certificate/Other (Section 1)',
            amount: 0,
            read_only: true
        }, {
            description: 'Equites/Bonds/Securities (Section 2)',
            amount: 0,
            read_only: true
        }, {
            description: 'Life Insurance Cash Value (Section 3)',
            amount: 0,
            read_only: true
        }, {
            description: 'Receivables / Contract Payments due to me (Section 4)',
            amount: 0,
            read_only: true
        }, {
            description: 'Primary Residence (Section 5)',
            amount: 0,
            read_only: true
        }, {
            description: 'Other Real Estate (Section 5)',
            amount: 0,
            read_only: true
        }, {
            description: 'Profit Sharing & Pension (Section 6 Vested)',
            amount: 0,
            read_only: true
        }, {
            description: 'Automobiles (Section 7)',
            amount: 0,
            read_only: true
        }, {
            description: 'Personal Property (Section 8)',
            amount: 0,
            read_only: true
        }, {
            description: 'Other Assets (Describe)',
            amount: 0,
            read_only: false
        }])
        addMoreBalanceSheetLiabilities([{
            description: 'Short Term Notes Due Financial Institutions (Section 9)',
            amount: 0,
            read_only: true
        }, {
            description: 'Other Short Term Liabilities/Notes Due (Section 9)',
            amount: 0,
            read_only: true
        }, {
            description: 'Credit Accounts and Bills Due (Section 10)',
            amount: 0,
            read_only: true
        }, {
            description: 'Insurance/Profit Sharing and Pension Loans (Section 3 and 6)',
            amount: 0,
            read_only: true
        }, {
            description: 'Mortgages on Primary Residence (Section 5)',
            amount: 0,
            read_only: true
        }, {
            description: 'Mortgages on Other Real Estate (Section5)',
            amount: 0,
            read_only: true
        }, {
            description: 'Taxes Payable',
            amount: 0,
            read_only: false
        }, {
            description: 'Other Liabilities (Describe)',
            amount: 0,
            read_only: false
        }])
        addMoreAnnualIncome([
            {
                title: 'Salary',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Bonuses/Commissions',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Dividends/Interest',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Net Real Estate Income',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Income From Alimony',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Income from Child Support',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Income - Maintenance Payments',
                self_amount: 0,
                spouse_amount: 0
            },
            {
                title: 'Other (List)',
                self_amount: 0,
                spouse_amount: 0
            }
        ]);
        addMoreSectionOneCashType({
            institution_name: '',
            title: '',
            balance_amount: 0
        })
        addMoreSectionOneRetiementType({
            institution_name: '',
            title: '',
            balance_amount: 0
        })
        addMoreSectionOneCertificateType({
            institution_name: '',
            title: '',
            balance_amount: 0
        })
        addMoreSectionTwoEquities({
            owner: '',
            holding_party: '',
            name_of_portfolio: '',
            amount: 0
        })
        addMoreSectionTwoEquitiesLoan({
            loan_against_portfolio: '',
            borrower_name: '',
            guarantor_name: '',
            loan_amount: 0
        })
        addMoreSectionTwoBonds({
            owner: '',
            holding_party: '',
            name_of_portfolio: '',
            amount: 0
        })
        addMoreSectionTwoBondLoan({
            loan_against_portfolio: '',
            borrower_name: '',
            guarantor_name: '',
            loan_amount: 0
        })
        addMoreSectionTwoSecurity({
            owner: '',
            holding_party: '',
            name_of_portfolio: '',
            amount: 0
        })
        addMoreSectionTwoSecurityLoan({
            loan_against_portfolio: '',
            borrower_name: '',
            guarantor_name: '',
            loan_amount: 0
        })
        addMoreSectionThreeLifeInsurance({
            insurance_company_name: '',
            insured_person_name: '',
            beneficiary_name: '',
            term_date: moment().format('MM-DD-YYYY'),
            term_amount: 0,
            whole_life_amount: 0,
            loan_amount: 0,
        })
        addMoreSectionFourReceivableDue({
            debtor_name: '',
            description: '',
            due_date: moment().format('MM-DD-YYYY'),
            repayment_terms: '',
            balance_amount: 0
        })
        addMoreSectionFiveRealEstatePrimaryProperty({
            address: '',
            build_date: new Date().getFullYear(),
            term: '',
            due_date: moment().format('MM-DD-YYYY'),
            monthly_payment_amount: 0,
            purchase_amount: 0,
            market_value_amount: 0,
            mortgage_balance_amount: 0
        })
        addMoreSectionFiveRealEstateOtherProperty({
            address: '',
            build_date: new Date().getFullYear(),
            term: '',
            due_date: moment().format('MM-DD-YYYY'),
            monthly_payment_amount: 0,
            purchase_amount: 0,
            market_value_amount: 0,
            mortgage_balance_amount: 0
        })
        addMoreSectionSixProfitSharing({
            name: '',
            account_type: '',
            value: 0,
            vested: 0,
            loans: 0
        })
        addMoreSectionSevenAutomobiles({
            year: 0,
            make: '',
            model: '',
            miles: '',
            loan_balance: 0,
            apprisal: 0,
            value: 0,
        })
        addMoreSectionEightPersonalProperty({
            description: '',
            insured: '',
            agent: '',
            phone: '',
            apprisal: 0,
            loans: 0,
            value: 0,
        })
        addMoreSectionNineNotes({
            name: '',
            date_borrowed: moment().format('MM-DD-YYYY'),
            amount_borrowed: 0,
            due_date: moment().format('MM-DD-YYYY'),
            payment: 0,
            balance: 0,
        })
        addMoreSectionNineOtherNotes({
            name: '',
            date_borrowed: moment().format('MM-DD-YYYY'),
            amount_borrowed: 0,
            due_date: moment().format('MM-DD-YYYY'),
            payment: 0,
            balance: 0,
        })
        addMoreSectionTen({
            name: '',
            high_balance: 0,
            start_date: moment().format('MM-DD-YYYY'),
            end_date: moment().format('MM-DD-YYYY'),
            payment: 0
        })

        setTotalBSAssets(0);
        setTotalBSLiabilities(0);
        setTotalBSNetWorth(0);
        setTotalAnnualIncomeSelf(0);
        setTotalAnnualIncomeSpouse(0);
        setTotalSectionOneCashType(0);
        setTotalSectionOneRetiementType(0);
        setTotalSectionOneCertificateType(0);
        setTotalEquities(0);
        setTotalEquitiesLoan(0);
        setTotalEquityNetWorth(0);
        setTotalBonds(0);
        setTotalBondsLoan(0);
        setTotalBondsNetWorth(0);
        setTotalSecurity(0);
        setTotalSecurityLoan(0);
        setTotalSecurityNetWorth(0);
        setTotalInsuranceTerm(0);
        setTotalInsuranceWhole(0);
        setTotalInsuranceLoan(0);
        setTotalReceivableDue(0);
        setTotalRealEstatePrimaryMrkt(0);
        setTotalRealEstatePrimaryMortgage(0);
        setTotalRealEstateOtherMrkt(0);
        setTotalRealEstateOtherMortgage(0);
        setTotalPensionValue(0);
        setTotalPensionVested(0);
        setTotalPensionLoan(0);
        setTotalAutoMobileLoan(0);
        setTotalAutoMobileAppraisal(0);
        setTotalAutoMobileValue(0);
        setTotalPropertyLoan(0);
        setTotalPropertyAppraisal(0);
        setTotalPropertyValue(0);
        setTotalFinancial(0);
        setTotalLiabilities(0);
        setTotalSectionTen(0);
        
        setTimeout(() => {
            if ( inputRef.current) {
                inputRef.current.focus();
            }
            window.scrollTo(0, 0)
        }, 0);
    }


    const { fields: selfDependantFields, append: addMoreSelf, remove: deleteSelf } = useFieldArray({
        control,
        name: 'personal_balance_sheet_info_self.dependants',
    });
    const { fields: spouseDependantFields, append: addMoreSpouse, remove: deleteSpouse } = useFieldArray({
        control,
        name: 'personal_balance_sheet_info_spouse.dependants',
    });
    const { fields: balanceSheetAssetsFields, append: addMoreBalanceSheetAssets, remove: deleteBalanceSheetAssets } = useFieldArray({
        control,
        name: 'balance_sheet_assets',
    });
    const { fields: balanceSheetLiabilitiesFields, append: addMoreBalanceSheetLiabilities, remove: deleteBalanceSheetLiabilities } = useFieldArray({
        control,
        name: 'balance_sheet_liabilities',
    });
    const { fields: annualIncomeFields, append: addMoreAnnualIncome, remove: deleteAnnualIncome } = useFieldArray({
        control,
        name: 'personal_balance_sheet_annual_income',
    });
    const { fields: sectionOneCashTypeFields, append: addMoreSectionOneCashType, remove: deleteSectionOneCashType } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_one_cash_type',
    });
    const { fields: sectionOneRetiementTypeFields, append: addMoreSectionOneRetiementType, remove: deleteSectionOneRetiementType } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_one_retirement_type',
    });
    const { fields: sectionOneCertificateTypeFields, append: addMoreSectionOneCertificateType, remove: deleteSectionOneCertificateType } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_one_certificate_type',
    });
    const { fields: sectionTwoEquitiesFields, append: addMoreSectionTwoEquities, remove: deleteSectionTwoEquities } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_two_equities',
    });
    const { fields: sectionTwoEquitiesLoanFields, append: addMoreSectionTwoEquitiesLoan, remove: deleteSectionTwoEquitiesLoan } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_two_equities_loan',
    });
    const { fields: sectionTwoBondsFields, append: addMoreSectionTwoBonds, remove: deleteSectionTwoBonds } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_two_bonds',
    });
    const { fields: sectionTwoBondLoanFields, append: addMoreSectionTwoBondLoan, remove: deleteSectionTwoBondLoan } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_two_bonds_loan',
    });

    const { fields: sectionTwoSecurityFields, append: addMoreSectionTwoSecurity, remove: deleteSectionTwoSecurity } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_two_securities',
    });

    const { fields: sectionTwoSecurityLoanFields, append: addMoreSectionTwoSecurityLoan, remove: deleteSectionTwoSecurityLoan } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_two_securities_loan',
    });

    const { fields: sectionThreeLifeInsuranceFields, append: addMoreSectionThreeLifeInsurance, remove: deleteSectionThreeLifeInsurance } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_three_life_insurance',
    });

    const { fields: sectionFourReceivableDueFields, append: addMoreSectionFourReceivableDue, remove: deleteSectionFourReceivableDue } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_four_receivable_due',
    });

    const { fields: sectionFiveRealEstatePrimaryPropertyFields, append: addMoreSectionFiveRealEstatePrimaryProperty, remove: deleteSectionFiveRealEstatePrimaryProperty } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_five_real_estate_primary_property',
    });

    const { fields: sectionFiveRealEstateOtherPropertyFields, append: addMoreSectionFiveRealEstateOtherProperty, remove: deleteSectionFiveRealEstateOtherProperty } = useFieldArray({
        control,
        name: 'personal_balance_sheet_section_five_real_estate_other_property',
    });

    const { fields: sectionSixProfitSharingFields, append: addMoreSectionSixProfitSharing, remove: deleteSectionSixProfitSharing } = useFieldArray({
        control,
        name: 'section_six_profit_sharing',
    });

    const { fields: sectionSevenAutomobilesFields, append: addMoreSectionSevenAutomobiles, remove: deleteSectionSevenAutomobiles } = useFieldArray({
        control,
        name: 'section_seven_automobiles',
    });

    const { fields: sectionEightPersonalPropertyFields, append: addMoreSectionEightPersonalProperty, remove: deleteSectionEightPersonalProperty } = useFieldArray({
        control,
        name: 'section_eight_personal_property',
    });

    const { fields: sectionNineNotesFields, append: addMoreSectionNineNotes, remove: deleteSectionNineNotes } = useFieldArray({
        control,
        name: 'section_nine_notes',
    });

    const { fields: sectionNineOtherNotesFields, append: addMoreSectionNineOtherNotes, remove: deleteSectionNineOtherNotes } = useFieldArray({
        control,
        name: 'section_nine_other_notes',
    });

    const { fields: sectionTenFields, append: addMoreSectionTen, remove: deleteSectionTen } = useFieldArray({
        control,
        name: 'section_ten',
    });


    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'month') {
            setSearchParams({
                ...searchParams,
                'month': e
            })
        } else if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }

    const fetchMonthlyIncome = () => {
        setIsLoading(true);
        const data = {
            // month: searchParams['month']['value'],
            year: searchParams['year']['value'],
            branch_id: +branchData['id']
        }
        getPersonalBalanceSheet(data, (message: string, resp: any) => {
            const data = resp.data.data;
            reset();

            if (data) {
                setIsEdit(true);
                setShowSubmitButton(false)
                reset({
                    personal_balance_sheet_info_self: data['personal_balance_sheet_info_self'],
                    personal_balance_sheet_info_spouse: data['personal_balance_sheet_info_spouse'],
                })

                data['balance_sheet_assets'].map((item: any) => {
                    addMoreBalanceSheetAssets({ ...item, read_only: true })
                })
                data['balance_sheet_liabilities'].map((item: any) => {
                    addMoreBalanceSheetLiabilities({ ...item, read_only: true })
                })
                var annualSelf = 0, annualSpouse = 0;
                data['personal_balance_sheet_annual_income'].map((item: any) => {
                    annualSelf += (parseFloat(item.self_amount) || 0);
                    annualSpouse += (parseFloat(item.spouse_amount) || 0);
                    addMoreAnnualIncome({ ...item })
                })
                var cashTypeAmount = 0;
                data['personal_balance_sheet_section_one_cash_type'].map((item: any) => {
                    cashTypeAmount += (parseFloat(item.balance_amount) || 0);
                    addMoreSectionOneCashType({ ...item })
                })
                var retiementTypeAmount = 0;
                data['personal_balance_sheet_section_one_retirement_type'].map((item: any) => {
                    retiementTypeAmount += (parseFloat(item.balance_amount) || 0);
                    addMoreSectionOneRetiementType({ ...item })
                })
                var certificateTypeAmount = 0;
                data['personal_balance_sheet_section_one_certificate_type'].map((item: any) => {
                    certificateTypeAmount += (parseFloat(item.balance_amount) || 0);
                    addMoreSectionOneCertificateType({ ...item })
                })
                var equityAmount = 0;
                data['personal_balance_sheet_section_two_equities'].map((item: any) => {
                    equityAmount += (parseFloat(item.amount) || 0);
                    addMoreSectionTwoEquities({ ...item })
                })
                var equityLoanAmount = 0;
                data['personal_balance_sheet_section_two_equities_loan'].map((item: any) => {
                    equityLoanAmount += (parseFloat(item.loan_amount) || 0);
                    addMoreSectionTwoEquitiesLoan({ ...item })
                })
                var bondAmount = 0;
                data['personal_balance_sheet_section_two_bonds'].map((item: any) => {
                    bondAmount += (parseFloat(item.amount) || 0);
                    addMoreSectionTwoBonds({ ...item })
                })
                var bondLoanAmount = 0;
                data['personal_balance_sheet_section_two_bonds_loan'].map((item: any) => {
                    bondLoanAmount += (parseFloat(item.loan_amount) || 0);
                    addMoreSectionTwoBondLoan({ ...item })
                })
                var securityAmount = 0;
                data['personal_balance_sheet_section_two_securities'].map((item: any) => {
                    securityAmount += (parseFloat(item.amount) || 0);
                    addMoreSectionTwoSecurity({ ...item })
                })
                var securityLoanAmount = 0;
                data['personal_balance_sheet_section_two_securities_loan'].map((item: any) => {
                    securityLoanAmount += (parseFloat(item.loan_amount) || 0);
                    addMoreSectionTwoSecurityLoan({ ...item })
                })
                var insuranceTermAmount = 0, insuranceWholeAmount = 0, insuranceLoanAmount = 0;
                data['personal_balance_sheet_section_three_life_insurance'].map((item: any) => {
                    insuranceTermAmount += (parseFloat(item.term_amount) || 0);
                    insuranceWholeAmount += (parseFloat(item.whole_life_amount) || 0);
                    insuranceLoanAmount += (parseFloat(item.loan_amount) || 0);
                    addMoreSectionThreeLifeInsurance({ ...item })
                })
                var receivableDueAmount = 0;
                data['personal_balance_sheet_section_four_receivable_due'].map((item: any) => {
                    receivableDueAmount += (parseFloat(item.balance_amount) || 0);
                    addMoreSectionFourReceivableDue({ ...item })
                })
                var realEstatePrimaryMrktAmount = 0, realEstatePrimaryMortgageAmount = 0;
                data['personal_balance_sheet_section_five_real_estate_primary_property'].map((item: any) => {
                    realEstatePrimaryMrktAmount += (parseFloat(item.market_value_amount) || 0);
                    realEstatePrimaryMortgageAmount += (parseFloat(item.mortgage_balance_amount) || 0);
                    addMoreSectionFiveRealEstatePrimaryProperty({ ...item })
                })
                var realEstateOtherMrktAmount = 0, realEstateOtherMortgageAmount = 0;
                data['personal_balance_sheet_section_five_real_estate_other_property'].map((item: any) => {
                    realEstateOtherMrktAmount += (parseFloat(item.market_value_amount) || 0);
                    realEstateOtherMortgageAmount += (parseFloat(item.mortgage_balance_amount) || 0);
                    // console.log('personal_balance_sheet_section_five_real_estate_other_property: ', item)
                    addMoreSectionFiveRealEstateOtherProperty({ ...item })
                })
                var pensionValueAmount = 0, pensionVestedAmount = 0, pensionLoanAmount = 0;
                data['section_six_profit_sharing'].map((item: any) => {
                    pensionValueAmount += (parseFloat(item.value) || 0);
                    pensionVestedAmount += (parseFloat(item.vested) || 0);
                    pensionLoanAmount += (parseFloat(item.loans) || 0);
                    addMoreSectionSixProfitSharing({ ...item })
                })
                var autoMobileLoanAmount = 0, autoMobileAppraisalAmount = 0, autoMobileValueAmount = 0;
                data['section_seven_automobiles'].map((item: any) => {
                    autoMobileLoanAmount += (parseFloat(item.loan_balance) || 0);
                    autoMobileAppraisalAmount += (parseFloat(item.apprisal) || 0);
                    autoMobileValueAmount += (parseFloat(item.value) || 0);
                    addMoreSectionSevenAutomobiles({ ...item })
                })
                var propertyLoanAmount = 0, propertyAppraisalAmount = 0, propertyValueAmount = 0;
                data['section_eight_personal_property'].map((item: any) => {
                    propertyLoanAmount += (parseFloat(item.loans) || 0);
                    propertyAppraisalAmount += (parseFloat(item.apprisal) || 0);
                    propertyValueAmount += (parseFloat(item.value) || 0);
                    addMoreSectionEightPersonalProperty({ ...item })
                })
                var financialAmount = 0;
                data['section_nine_notes'].map((item: any) => {
                    financialAmount += (parseFloat(item.balance) || 0);
                    addMoreSectionNineNotes({ ...item })
                })
                var liabilitiesAmount = 0;
                data['section_nine_other_notes'].map((item: any) => {
                    liabilitiesAmount += (parseFloat(item.balance) || 0);
                    addMoreSectionNineOtherNotes({ ...item })
                })
                var sectionTenAmount = 0;
                data['section_ten'].map((item: any) => {
                    sectionTenAmount += (parseFloat(item['payment']) || 0);
                    addMoreSectionTen({
                        ...item,
                        high_balance: item['high_balance_amount'],
                        payment: item['payment']
                    })
                })


                setTotalAnnualIncomeSelf(annualSelf);
                setTotalAnnualIncomeSpouse(annualSpouse);
                setTotalSectionOneCashType(cashTypeAmount);
                setTotalSectionOneRetiementType(retiementTypeAmount);
                setTotalSectionOneCertificateType(certificateTypeAmount);
                setTotalEquities(equityAmount);
                setTotalEquitiesLoan(equityLoanAmount);
                setTotalBonds(bondAmount);
                setTotalBondsLoan(bondLoanAmount);
                setTotalSecurity(securityAmount);
                setTotalSecurityLoan(securityLoanAmount);
                setTotalInsuranceTerm(insuranceTermAmount);
                setTotalInsuranceWhole(insuranceWholeAmount);
                setTotalInsuranceLoan(insuranceLoanAmount);
                setTotalReceivableDue(receivableDueAmount);
                setTotalRealEstatePrimaryMrkt(realEstatePrimaryMrktAmount);
                setTotalRealEstatePrimaryMortgage(realEstatePrimaryMortgageAmount);
                setTotalRealEstateOtherMrkt(realEstateOtherMrktAmount);
                setTotalRealEstateOtherMortgage(realEstateOtherMortgageAmount);
                setTotalPensionValue(pensionValueAmount);
                setTotalPensionVested(pensionVestedAmount);
                setTotalPensionLoan(pensionLoanAmount);
                setTotalAutoMobileLoan(autoMobileLoanAmount);
                setTotalAutoMobileAppraisal(autoMobileAppraisalAmount);
                setTotalAutoMobileValue(autoMobileValueAmount);
                setTotalPropertyLoan(propertyLoanAmount);
                setTotalPropertyAppraisal(propertyAppraisalAmount);
                setTotalPropertyValue(propertyValueAmount);
                setTotalFinancial(financialAmount);
                setTotalLiabilities(liabilitiesAmount);
                setTotalSectionTen(sectionTenAmount);
                calculateTotalBSAssets();
                calculateTotalBSLiabilities();

                setTimeout(() => {
                    if ( inputRef.current) {
                        inputRef.current.focus();
                    }
                    window.scrollTo(0, 0)
                }, 0);

            } else {
                setIsEdit(false);
                setShowSubmitButton(true);
                reset(initData);
                initialiseForm();
            }

            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
        })
    }

    const saveMonthlyIncome = (data: any) => {
        setIsLoading(true);
        const payload = {
            queryString: {
                year: searchParams['year']['value'],
                branch_id: +branchData['id']
            },
            data
        }

        createPersonalBalanceSheet(payload, (message: string, resp: any) => {
            captureContentAndGeneratePDF();
            // generatePdf();
            setIsLoading(false);
            toast.success(message);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const generatePdf = async () => {
        const element = document.querySelector('.personal-balance-sheet.main-table') as HTMLElement;

        if (!element) {
            alert('Table not found!');
            return;
        }

        // Use html2pdf.js to generate the PDF
        const opt = {
            margin: 0.5,
            filename: 'personal_balance_sheet.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf()
            .from(element)
            .set(opt)
            .save();
    };


    const captureContentAndGeneratePDF = async () => {
        const pdf = new jsPDF('p', 'pt', 'a4');

        for (let i = 0; i < pagesRefs.current.length; i++) {
            const pageRef = pagesRefs.current[i];

            if (pageRef === null) {
                continue; // Skip if ref is null
            }

            // Capture the content of each page
            const canvas = await html2canvas(pageRef, { scale: 3 });

            // Add a new page if not the first page
            if (i > 0) {
                pdf.addPage();
            }

            // Add the captured content as an image to the PDF
            pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        }

        // Save or download the PDF
        pdf.save(`Personal Balance Sheet(${searchParams['year']['value']}).pdf`);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            event.preventDefault();
        }
    };

    const submitForm = () => {
        handleSubmit(saveMonthlyIncome)();
    };

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Personal Balance Sheet</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="filter-wrap">
                                {/* <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'month')}
                                        options={MONTHS}
                                        defaultValue={searchParams['month']}
                                        className={'zindex-10'}
                                    />
                                </div> */}
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchMonthlyIncome} className="generate-btn">Generate Form</button>
                                </div>
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard personal-balance-sheet">
                    <div style={{ padding: 0, margin: 0, textAlign: 'center' }}>
                        <Form onKeyDown={handleKeyDown} /*onSubmit={handleSubmit(saveMonthlyIncome)}*/>
                            <div>
                                <>{console.log('error: ', errors)}</>
                                <table className="personal-balance-sheet main-table" ref={ref => pagesRefs.current[0] = ref}>
                                    {/* <div ref={ref => pagesRefs.current[0] = ref}> */}
                                    <tr>
                                        <td>
                                            <h2 className="header">PERSONAL BALANCE SHEET</h2>
                                            <table className="section-table" id="personal-table">
                                                <tr>
                                                    <td>
                                                        <select className="select " {...register("personal_balance_sheet_info_self.salutation")}>
                                                            <option value="Mr" selected>Mr.</option>
                                                            <option value="Mrs">Mrs.</option>
                                                            <option value="Ms">Ms.</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" {...register("personal_balance_sheet_info_self.last_name")} placeholder="Last Name" className='' ref={inputRef}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input type="text" {...register("personal_balance_sheet_info_self.first_name")} placeholder="First Name" className='' />
                                                    </td>
                                                    <td>
                                                        <input type="text" {...register("personal_balance_sheet_info_self.initial")} placeholder="Initial" className='' />
                                                    </td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>Primary Residence - Home Street Address (No P.O. Box)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <input type="text" {...register("personal_balance_sheet_info_self.current_address")} placeholder="Home Street Address" className='' />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>City</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.current_city")} placeholder="City" /></td>
                                                    <td>State</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.current_state")} placeholder="State" /></td>
                                                    <td>Zip</td>
                                                    <td>
                                                        <input type="text" className="" {...register("personal_balance_sheet_info_self.current_zip")} placeholder="Zip" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Country</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.current_country")} placeholder="Country" /></td>
                                                    <td>Social Security #</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.current_social_security_number")} placeholder="Social Security #" /></td>
                                                    <td>Date of Birth</td>
                                                    <td>
                                                        <Controller
                                                            name="personal_balance_sheet_info_self.current_date_of_birth"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    key={1}
                                                                    selected={field.value ? new Date(field.value) : new Date()}
                                                                    onChange={(e: any) => {
                                                                        field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                    }}
                                                                    className=''
                                                                    dateFormat={'MM/dd/yyyy'}
                                                                    maxDate={new Date()}
                                                                    calendarIconClassname='tex-end'
                                                                    showYearDropdown
                                                                    scrollableYearDropdown
                                                                    yearDropdownItemNumber={40}
                                                                    showMonthDropdown

                                                                />
                                                            )}
                                                        />
                                                    </td>

                                                    {/* <td><input type="date" {...register("personal_balance_sheet_info_self.current_date_of_birth")} /></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Residence</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.current_residence")} placeholder="Residence" /></td>
                                                    <td>Own/Rent/Other</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.current_owner_type")} placeholder="Own/Rent/Other" /></td>
                                                    <td>Years</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.current_years")} placeholder="Years" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Months</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.current_months")} placeholder="Months" /></td>
                                                    <td>Date</td>
                                                    <td>
                                                        <Controller
                                                            name="personal_balance_sheet_info_self.current_date"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <DatePicker
                                                                        key={2}
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(e: any) => {
                                                                            field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                        }}
                                                                        className=''
                                                                        dateFormat={'MM/dd/yyyy'}
                                                                        maxDate={new Date()}
                                                                        calendarIconClassname='tex-end'
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={40}
                                                                        showMonthDropdown
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* <input type="date" {...register("personal_balance_sheet_info_self.current_address.date")} /> */}
                                                    </td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>Previous Address (If less than 5 years at present)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_address")} placeholder="Previous Address" /></td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_city")} placeholder="City" /></td>
                                                    <td>State</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_state")} placeholder="State" /></td>
                                                    <td>Zip</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_zip")} placeholder="Zip" /></td>

                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>
                                                        <Controller
                                                            name="personal_balance_sheet_info_self.previous_date"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <DatePicker
                                                                        key={3}
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(e: any) => {
                                                                            field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                        }}
                                                                        className=''
                                                                        dateFormat={'MM/dd/yyyy'}
                                                                        maxDate={new Date()}
                                                                        calendarIconClassname='tex-end'
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={40}
                                                                        showMonthDropdown
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* <input type="date" {...register("personal_balance_sheet_info_self.previous_address.date")} /> */}
                                                    </td>
                                                    <td>Own/Rent/Other</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_owner_type")} placeholder="Own/Rent/Other" /></td>
                                                    <td>Years</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.previous_years")} placeholder="Years" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Months</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.previous_months")} placeholder="Months" /></td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                                <tr>
                                                    <td>Personal Phone</td>
                                                    <td><input type="number" className="" {...register("personal_balance_sheet_info_self.previous_personal_phone")} placeholder="Personal Phone" /></td>
                                                    <td>Work Phone</td>
                                                    <td><input type="number" className="" {...register("personal_balance_sheet_info_self.previous_work_phone")} placeholder="Work Phone" /></td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td>Employer</td>
                                                    <td colSpan={3}><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_name")} placeholder="Employer" /></td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_city")} placeholder="City" /></td>
                                                    <td>Work Street Address</td>
                                                    <td colSpan={3}><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_address")} placeholder="Work Street Address" /></td>
                                                </tr>
                                                <tr>
                                                    <td>State</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_state")} placeholder="State" /></td>
                                                    <td>Zip</td>
                                                    <td colSpan={3}><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_zip")} placeholder="Zip" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Position</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_position")} placeholder="Position" /></td>
                                                    <td>Title</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_self.employer_title")} placeholder="Title" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Service Yrs.</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.employer_service_yrs")} placeholder="Service Years" /></td>
                                                    <td>Service Mos.</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.employer_service_months")} placeholder="Service Months" /></td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td>Previous Employer</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_employer_name")} placeholder="Previous Employer" /></td>
                                                    <td>Service Yrs.</td>
                                                    <td colSpan={2}><input type="number" {...register("personal_balance_sheet_info_self.previous_employer_service_yrs")} placeholder="Service Years" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Service Mos.</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_self.previous_employer_service_months")} placeholder="Service Months" /></td>
                                                    <td>WK Phone</td>
                                                    <td colSpan={3}><input type="number" className="" {...register("personal_balance_sheet_info_self.previous_employer_wk_phone")} placeholder="WK Phone" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Position</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_employer_position")} placeholder="Position" /></td>
                                                    <td>Title</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_self.previous_employer_title")} placeholder="Title" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>Dependents</td>
                                                    <td colSpan={3}>Relationship</td>
                                                </tr>

                                                {selfDependantFields.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={3}>
                                                            <input type="text" className="" {...register(`personal_balance_sheet_info_self.dependants.${index}.name`)} placeholder="Name" />
                                                        </td>
                                                        <td colSpan={3}>
                                                            <input type="text" className="" {...register(`personal_balance_sheet_info_self.dependants.${index}.relationship`)} placeholder="Relation" />
                                                        </td>
                                                        {/* <button type="button" onClick={() => remove(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <button type='button' className="button" onClick={() => addMoreSelf({ name: '', relationship: '' })}>Add Row</button>
                                                            </td>
                                                        </tr>
                                                    ) || <></>
                                                }
                                            </table>
                                        </td>

                                        <td>
                                            <h2 className="header-spouse">SPOUSE/SIGNIFICANT OTHER</h2>
                                            <table className="section-table" id="personal-table">
                                                <tr>
                                                    <td>
                                                        <select className="select " {...register("personal_balance_sheet_info_spouse.salutation")}>
                                                            <option value="Mr">Mr.</option>
                                                            <option value="Mrs" selected>Mrs.</option>
                                                            <option value="Ms">Ms.</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" {...register("personal_balance_sheet_info_spouse.last_name")} placeholder="Last Name" className='' />
                                                    </td>
                                                    <td>
                                                        <input type="text" {...register("personal_balance_sheet_info_spouse.first_name")} placeholder="First Name" className='' />
                                                    </td>
                                                    <td>
                                                        <input type="text" {...register("personal_balance_sheet_info_spouse.initial")} placeholder="Initial" className='' />
                                                    </td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>Primary Residence - Home Street Address (No P.O. Box)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <input type="text" {...register("personal_balance_sheet_info_spouse.current_address")} placeholder="Home Street Address" className='' />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>City</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_city")} placeholder="City" /></td>
                                                    <td>State</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_state")} placeholder="State" /></td>
                                                    <td>Zip</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_zip")} placeholder="Zip" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Country</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_country")} placeholder="Country" /></td>
                                                    <td>Social Security #</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_social_security_number")} placeholder="Social Security #" /></td>
                                                    <td>Date of Birth</td>
                                                    <td>
                                                        <Controller
                                                            name="personal_balance_sheet_info_spouse.current_date_of_birth"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <DatePicker
                                                                        key={4}
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(e: any) => {
                                                                            field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                        }}
                                                                        className=''
                                                                        dateFormat={'MM/dd/yyyy'}
                                                                        maxDate={new Date()}
                                                                        calendarIconClassname='tex-end'
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={40}
                                                                        showMonthDropdown
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* <input type="date" {...register("personal_balance_sheet_info_spouse.current_address.date_of_birth")} /> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Residence</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_residence")} placeholder="Residence" /></td>
                                                    <td>Own/Rent/Other</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.current_owner_type")} placeholder="Own/Rent/Other" /></td>
                                                    <td>Years</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.current_years")} placeholder="Years" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Months</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.current_months")} placeholder="Months" /></td>
                                                    <td>Date</td>
                                                    <td>
                                                        <Controller
                                                            name="personal_balance_sheet_info_spouse.current_date"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <DatePicker
                                                                        key={5}
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(e: any) => {
                                                                            field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                        }}
                                                                        className=''
                                                                        dateFormat={'MM/dd/yyyy'}
                                                                        maxDate={new Date()}
                                                                        calendarIconClassname='tex-end'
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={40}
                                                                        showMonthDropdown
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* <input type="date" {...register("personal_balance_sheet_info_spouse.current_date")} /> */}
                                                    </td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>Previous Address (If less than 5 years at present)</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_address")} placeholder="Previous Address" /></td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_city")} placeholder="City" /></td>
                                                    <td>State</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_state")} placeholder="State" /></td>
                                                    <td>Zip</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_zip")} placeholder="Zip" /></td>

                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>
                                                        <Controller
                                                            name="personal_balance_sheet_info_spouse.previous_date"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <>
                                                                    <DatePicker
                                                                        key={6}
                                                                        selected={field.value ? new Date(field.value) : new Date()}
                                                                        onChange={(e: any) => {
                                                                            field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                        }}
                                                                        className=''
                                                                        dateFormat={'MM/dd/yyyy'}
                                                                        maxDate={new Date()}
                                                                        calendarIconClassname='tex-end'
                                                                        showYearDropdown
                                                                        scrollableYearDropdown
                                                                        yearDropdownItemNumber={40}
                                                                        showMonthDropdown
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* <input type="date" {...register("personal_balance_sheet_info_spouse.previous_date")} /> */}
                                                    </td>
                                                    <td>Own/Rent/Other</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_owner_type")} placeholder="Own/Rent/Other" /></td>
                                                    <td>Years</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.previous_years")} placeholder="Years" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Months</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.previous_months")} placeholder="Months" /></td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                                <tr>
                                                    <td>Personal Phone</td>
                                                    <td><input type="number" className="" {...register("personal_balance_sheet_info_spouse.previous_personal_phone")} placeholder="Personal Phone" /></td>
                                                    <td>Work Phone</td>
                                                    <td><input type="nmber" className="" {...register("personal_balance_sheet_info_spouse.previous_work_phone")} placeholder="Work Phone" /></td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td>Employer</td>
                                                    <td colSpan={3}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_name")} placeholder="Employer" /></td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_city")} placeholder="City" /></td>
                                                    <td>Work Street Address</td>
                                                    <td colSpan={3}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_address")} placeholder="Work Street Address" /></td>
                                                </tr>
                                                <tr>
                                                    <td>State</td>
                                                    <td><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_state")} placeholder="State" /></td>
                                                    <td>Zip</td>
                                                    <td colSpan={3}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_zip")} placeholder="Zip" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Position</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_position")} placeholder="Position" /></td>
                                                    <td>Title</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.employer_title")} placeholder="Title" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Service Yrs.</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.employer_service_yrs")} placeholder="Service Years" /></td>
                                                    <td>Service Mos.</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.employer_service_months")} placeholder="Service Months" /></td>
                                                    <td colSpan={2}></td>
                                                </tr>
                                                <tr>
                                                    <td>Previous Employer</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_employer_name")} placeholder="Previous Employer" /></td>
                                                    <td>Service Yrs.</td>
                                                    <td colSpan={2}><input type="number" {...register("personal_balance_sheet_info_spouse.previous_employer_service_yrs")} placeholder="Service Years" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Service Mos.</td>
                                                    <td><input type="number" {...register("personal_balance_sheet_info_spouse.previous_employer_service_months")} placeholder="Service Months" /></td>
                                                    <td>WK Phone</td>
                                                    <td colSpan={3}><input type="number" className="" {...register("personal_balance_sheet_info_spouse.previous_employer_wk_phone")} placeholder="WK Phone" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Position</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_employer_position")} placeholder="Position" /></td>
                                                    <td>Title</td>
                                                    <td colSpan={2}><input type="text" className="" {...register("personal_balance_sheet_info_spouse.previous_employer_title")} placeholder="Title" /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>Dependents</td>
                                                    <td colSpan={3}>Relationship</td>
                                                </tr>
                                                {spouseDependantFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={3}>
                                                            <input type="text" className="" {...register(`personal_balance_sheet_info_spouse.dependants.${index}.name`)} placeholder="Name" />
                                                        </td>
                                                        <td colSpan={3}>
                                                            <input type="text" className="" {...register(`personal_balance_sheet_info_spouse.dependants.${index}.relationship`)} placeholder="Relation" />
                                                        </td>
                                                        {/* <button type="button" onClick={() => remove(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <button type='button' className="button" onClick={() => addMoreSpouse({ name: '', relationship: '' })}>Add Row</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <h2 className="header">BALANCE SHEET</h2>
                                            <table className="section-table">
                                                <tr>
                                                    <td>
                                                        <h2 className="balance-header">ASSETS</h2>
                                                        <table className="section-table" id="personal-table">
                                                            <tr>
                                                                <td colSpan={3}>Title</td>
                                                                <td colSpan={3}>Amount (in $)</td>
                                                            </tr>
                                                            {balanceSheetAssetsFields.map((item: any, index: number) => (
                                                                <tr key={item.id}>
                                                                    <td colSpan={3}>
                                                                        <input type="text" className="" {...register(`balance_sheet_assets.${index}.description`)} placeholder="Other Assets (Describe)" readOnly={balanceSheetAssetsFields[index]['read_only']} />
                                                                    </td>
                                                                    <td colSpan={3}>
                                                                        <input type="number" className="" {...register(`balance_sheet_assets.${index}.amount`)} placeholder="Amount" readOnly={balanceSheetAssetsFields[index]['read_only']} onChange={(event) => {
                                                                            const newFields = [...balanceSheetAssetsFields];
                                                                            newFields[index].amount = +parseFloat(event.target.value).toFixed(2) || 0;
                                                                            calculateTotalBSAssets();
                                                                        }} />
                                                                    </td>
                                                                    {/* <button type="button" onClick={() => remove(index)}>Remove</button> */}
                                                                </tr>
                                                            ))}
                                                            {
                                                                !isEdit && (
                                                                    <tr>
                                                                        <td colSpan={6}>
                                                                            <button type='button' className="button" onClick={() => addMoreBalanceSheetAssets({ description: '', amount: 0, read_only: false })}>Add Row</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }

                                                            <tr className="total-row">
                                                                <td colSpan={3}>Total</td>
                                                                <td colSpan={3}>${parseFloat('' + totalBSAssets).toFixed(2)}</td>
                                                            </tr>
                                                        </table>
                                                    </td>

                                                    <td>
                                                        <h2 className="balance-header">LIABILITIES</h2>
                                                        <table className="section-table" id="personal-table">
                                                            <tr>
                                                                <td colSpan={3}>Title</td>
                                                                <td colSpan={3}>Amount (in $)</td>
                                                            </tr>
                                                            {balanceSheetLiabilitiesFields.map((item: any, index: number) => (
                                                                <tr key={item.id}>
                                                                    <td colSpan={3}>
                                                                        <input type="text" className="" {...register(`balance_sheet_liabilities.${index}.description`)} placeholder="Other Liabilities (Describe)" readOnly={balanceSheetLiabilitiesFields[index]['read_only']} />
                                                                    </td>
                                                                    <td colSpan={3}>
                                                                        <input type="number" className="" {...register(`balance_sheet_liabilities.${index}.amount`)} placeholder="Amount" readOnly={balanceSheetLiabilitiesFields[index]['read_only']}
                                                                            onChange={(event) => {
                                                                                const newFields = [...balanceSheetLiabilitiesFields];
                                                                                newFields[index].amount = +parseFloat(event.target.value).toFixed(2) || 0;
                                                                                calculateTotalBSLiabilities();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    {/* <button type="button" onClick={() => remove(index)}>Remove</button> */}
                                                                </tr>
                                                            ))}
                                                            {
                                                                !isEdit && (
                                                                    <tr>
                                                                <td colSpan={6}>
                                                                    <button type='button' className="button" onClick={() => addMoreBalanceSheetLiabilities({ description: '', amount: 0, read_only: false })}>Add Row</button>
                                                                </td>
                                                            </tr>
                                                                )
                                                            }
                                                            
                                                            <tr className="total-row">
                                                                <td colSpan={3}>Total</td>
                                                                <td colSpan={3}>${parseFloat('' + totalBSLiabilities).toFixed(2)}</td>
                                                            </tr>
                                                            <tr className="total-row">
                                                                <td colSpan={3}>Net Worth</td>
                                                                <td colSpan={3}>${parseFloat('' + totalBSNetWorth).toFixed(2)}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <h2 className="header">ANNUAL INCOME</h2>
                                            <table className="section-table">
                                                <tr>
                                                    <td colSpan={6}>Annual Income</td>
                                                    <td colSpan={4}>You (in $)</td>
                                                    <td colSpan={2}>Spouse (in $)</td>
                                                </tr>
                                                {annualIncomeFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={8}>
                                                            <input
                                                                type='text'
                                                                {...register(`personal_balance_sheet_annual_income.${index}.title`)}
                                                                placeholder="Title"
                                                            />
                                                        </td>
                                                        <td colSpan={2}>
                                                            <input
                                                                type='number'
                                                                {...register(`personal_balance_sheet_annual_income.${index}.self_amount`)}
                                                                placeholder="Amount"
                                                                onChange={(event) => {
                                                                    const newFields = [...annualIncomeFields];
                                                                    newFields[index].self_amount = parseFloat(event.target.value) || 0;
                                                                    setTotalAnnualIncomeSelf(annualIncomeFields.reduce((acc: number, item: any) => acc + (parseFloat(item.self_amount) || 0), 0));
                                                                }}
                                                            />
                                                        </td>
                                                        <td colSpan={2}>
                                                            <input
                                                                type='number'
                                                                {...register(`personal_balance_sheet_annual_income.${index}.spouse_amount`)}
                                                                placeholder="Amount"
                                                                onChange={(event) => {
                                                                    const newFields = [...annualIncomeFields];
                                                                    newFields[index].spouse_amount = parseFloat(event.target.value) || 0;
                                                                    setTotalAnnualIncomeSpouse(annualIncomeFields.reduce((acc: number, item: any) => acc + (parseFloat(item.spouse_amount) || 0), 0));
                                                                }}
                                                            />
                                                        </td>
                                                        {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                    <td colSpan={12}>
                                                        <button type="button" className='button' onClick={() => addMoreAnnualIncome({ title: '', self_amount: 0, spouse_amount: 0 })}>Add Row</button>
                                                    </td>
                                                </tr>
                                                    )
                                                }
                                                
                                                <tr className="total-row">
                                                    <td colSpan={8}>Total</td>
                                                    <td colSpan={2}>${parseFloat('' + totalAnnualIncomeSelf).toFixed(2)}</td>
                                                    <td colSpan={2}>${parseFloat('' + totalAnnualIncomeSpouse).toFixed(2)}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                {/* </div> */}
                                {/* <div ref={ref => pagesRefs.current[1] = ref}> */}
                                <table className="personal-balance-sheet main-table" ref={ref => pagesRefs.current[1] = ref}>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                SECTION 1 / CASH, SAVINGS, CERTIFICATES AND IRA ACCOUNTS
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={6}>Name of Bank or Financial Institution</td>
                                                        <td colSpan={4}>Cash (Savings/Checking)</td>
                                                        <td colSpan={2}>Balance (in $)</td>
                                                    </tr>
                                                    {sectionOneCashTypeFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={6}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_one_cash_type.${index}.institution_name`)}
                                                                    placeholder="Institution name"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_one_cash_type.${index}.title`)}
                                                                    placeholder="Title"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_one_cash_type.${index}.balance_amount`)}
                                                                    placeholder="Balance"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionOneCashTypeFields];
                                                                        newFields[index].balance_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalSectionOneCashType(sectionOneCashTypeFields.reduce((acc: number, item: any) => acc + (parseFloat(item.balance_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionOneCashType({ institution_name: '', title: '', balance_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        ) || <></>
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalSectionOneCashType).toFixed(2)}</td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={6}>Name of Financial Institution</td>
                                                        <td colSpan={4}>All Retirement Accounts (IRA/401k/SEP/Other)</td>
                                                        <td colSpan={2}>Balance (in $)</td>
                                                    </tr>
                                                    {sectionOneRetiementTypeFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={6}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_one_retirement_type.${index}.institution_name`)}
                                                                    placeholder="Institution name"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_one_retirement_type.${index}.title`)}
                                                                    placeholder="Title"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_one_retirement_type.${index}.balance_amount`)}
                                                                    placeholder="Balance"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionOneRetiementTypeFields];
                                                                        newFields[index].balance_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalSectionOneRetiementType(sectionOneRetiementTypeFields.reduce((acc: number, item: any) => acc + (parseFloat(item.balance_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (<tr>
                                                            <td colSpan={12}>
                                                                <button type="button" className='button' onClick={() => addMoreSectionOneRetiementType({ institution_name: '', title: '', balance_amount: 0 })}>Add Row</button>
                                                            </td>
                                                        </tr>)
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalSectionOneRetiementType).toFixed(2)}</td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={6}>Name of Financial Institution</td>
                                                        <td colSpan={4}>Certificates/Other</td>
                                                        <td colSpan={2}>Balance (in $)</td>
                                                    </tr>
                                                    {sectionOneCertificateTypeFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={6}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_one_certificate_type.${index}.institution_name`)}
                                                                    placeholder="Description"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_one_certificate_type.${index}.title`)}
                                                                    placeholder="Title"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_one_certificate_type.${index}.balance_amount`)}
                                                                    placeholder="Balance"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionOneCertificateTypeFields];
                                                                        newFields[index].balance_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalSectionOneCertificateType(sectionOneCertificateTypeFields.reduce((acc: number, item: any) => acc + (parseFloat(item.balance_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionOneCertificateType({ institution_name: '', title: '', balance_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalSectionOneCertificateType).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                SECTION 2 / EQUITIES
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={4}>Owner of Portfolio</td>
                                                        <td colSpan={4}>Brokerage Holding EQUITY</td>
                                                        <td colSpan={2}>NAME Of Portfolio</td>
                                                        <td colSpan={2}>Portfolio Value (in $)</td>
                                                    </tr>
                                                    {sectionTwoEquitiesFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_equities.${index}.owner`)}
                                                                    placeholder="Owner"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_equities.${index}.holding_party`)}
                                                                    placeholder="Holding party"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    {...register(`personal_balance_sheet_section_two_equities.${index}.name_of_portfolio`)}
                                                                    placeholder="Portfolio name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_two_equities.${index}.amount`)}
                                                                    placeholder="Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTwoEquitiesFields];
                                                                        newFields[index].amount = parseFloat(event.target.value) || 0;
                                                                        setTotalEquities(sectionTwoEquitiesFields.reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTwoEquities({ owner: '', holding_party: '', name_of_portfolio: '', amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr>
                                                        <td colSpan={4}>Loan Against Portfolio</td>
                                                        <td colSpan={4}>Name of Borrower</td>
                                                        <td colSpan={2}>Name Of Portfolio Guaranteeing Loan</td>
                                                        <td colSpan={2}>Amount of Loan (in $)</td>
                                                    </tr>
                                                    {sectionTwoEquitiesLoanFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_equities_loan.${index}.loan_against_portfolio`)}
                                                                    placeholder="Loan"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_equities_loan.${index}.borrower_name`)}
                                                                    placeholder="Borrower name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_equities_loan.${index}.guarantor_name`)}
                                                                    placeholder="Guarantor name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_two_equities_loan.${index}.loan_amount`)}
                                                                    placeholder="Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTwoEquitiesLoanFields];
                                                                        newFields[index].loan_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalEquitiesLoan(sectionTwoEquitiesLoanFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loan_amount) + 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTwoEquitiesLoan({ loan_against_portfolio: '', borrower_name: '', guarantor_name: '', loan_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalEquityNetWorth).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                SECTION 2 / BONDS
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={4}>Owner of Portfolio</td>
                                                        <td colSpan={4}>Brokerage Holding EQUITY</td>
                                                        <td colSpan={2}>NAME Of Portfolio</td>
                                                        <td colSpan={2}>Portfolio Value (in $)</td>
                                                    </tr>
                                                    {sectionTwoBondsFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_bonds.${index}.owner`)}
                                                                    placeholder="Owner"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_bonds.${index}.holding_party`)}
                                                                    placeholder="Holding party"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_bonds.${index}.name_of_portfolio`)}
                                                                    placeholder="Portfolio"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_two_bonds.${index}.amount`)}
                                                                    placeholder="Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTwoBondsFields];
                                                                        newFields[index].amount = parseFloat(event.target.value) || 0;
                                                                        setTotalBonds(sectionTwoBondsFields.reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTwoBonds({ owner: '', holding_party: '', name_of_portfolio: '', amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    <tr>
                                                        <td colSpan={4}>Loan Against Portfolio</td>
                                                        <td colSpan={4}>Name of Borrower</td>
                                                        <td colSpan={2}>Name Of Portfolio Guaranteeing Loan</td>
                                                        <td colSpan={2}>Amount of Loan (in $)</td>
                                                    </tr>
                                                    {sectionTwoBondLoanFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_bonds_loan.${index}.loan_against_portfolio`)}
                                                                    placeholder="Loan"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_bonds_loan.${index}.borrower_name`)}
                                                                    placeholder="Borrower"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_bonds_loan.${index}.guarantor_name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_two_bonds_loan.${index}.loan_amount`)}
                                                                    placeholder="Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTwoBondLoanFields];
                                                                        newFields[index].loan_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalBondsLoan(sectionTwoBondLoanFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loan_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTwoBondLoan({ loan_against_portfolio: '', borrower_name: '', guarantor_name: '', loan_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalBondsNetWorth).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                SECTION 2 / Other Securities
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={4}>Owner of Portfolio</td>
                                                        <td colSpan={4}>Brokerage Holding EQUITY</td>
                                                        <td colSpan={2}>NAME Of Portfolio</td>
                                                        <td colSpan={2}>Portfolio Value (in $)</td>
                                                    </tr>
                                                    {sectionTwoSecurityFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_securities.${index}.owner`)}
                                                                    placeholder="Owner"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_securities.${index}.holding_party`)}
                                                                    placeholder="Holding bond"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_securities.${index}.name_of_portfolio`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_two_securities.${index}.amount`)}
                                                                    placeholder="Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTwoSecurityFields];
                                                                        newFields[index].amount = parseFloat(event.target.value) || 0;
                                                                        setTotalSecurity(sectionTwoSecurityFields.reduce((acc: number, item: any) => acc + (parseFloat(item.amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTwoSecurity({ owner: '', holding_party: '', name_of_portfolio: '', amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr>
                                                        <td colSpan={4}>Loan Against Portfolio</td>
                                                        <td colSpan={4}>Name of Borrower</td>
                                                        <td colSpan={2}>Name Of Portfolio Guaranteeing Loan</td>
                                                        <td colSpan={2}>Amount of Loan (in $)</td>
                                                    </tr>
                                                    {sectionTwoSecurityLoanFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_securities_loan.${index}.loan_against_portfolio`)}
                                                                    placeholder="Loan"
                                                                />
                                                            </td>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_securities_loan.${index}.borrower_name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_two_securities_loan.${index}.guarantor_name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_two_securities_loan.${index}.loan_amount`)}
                                                                    placeholder="Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTwoSecurityLoanFields];
                                                                        newFields[index].loan_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalSecurityLoan(sectionTwoSecurityLoanFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loan_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTwoSecurityLoan({ loan_against_portfolio: '', borrower_name: '', guarantor_name: '', loan_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalSecurityNetWorth).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                {/* </div> */}
                                {/* <div ref={ref => pagesRefs.current[1] = ref}> */}
                                <table className="personal-balance-sheet main-table" ref={ref => pagesRefs.current[2] = ref}>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 3 / LIFE INSURANCE
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={3}>Insurance Company Name</td>
                                                        <td colSpan={3}>Insured Name</td>
                                                        <td colSpan={2}>Beneficiary Name</td>
                                                        <td colSpan={1}>Term Date</td>
                                                        <td colSpan={1}>Term Value (in $)</td>
                                                        <td colSpan={1}>Whole Life (in $)</td>
                                                        <td colSpan={1}>Loans (in $)</td>
                                                    </tr>
                                                    {sectionThreeLifeInsuranceFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_three_life_insurance.${index}.insurance_company_name`)}
                                                                    placeholder="Insurance Company"
                                                                />
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_three_life_insurance.${index}.insured_person_name`)}
                                                                    placeholder="Insured Person"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_three_life_insurance.${index}.beneficiary_name`)}
                                                                    placeholder="Beneficiary"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`personal_balance_sheet_section_three_life_insurance.${index}.term_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <DatePicker
                                                                                key={7}
                                                                                selected={field.value ? new Date(field.value) : new Date()}
                                                                                onChange={(e: any) => {
                                                                                    field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                                }}
                                                                                className=''
                                                                                dateFormat={'MM/dd/yyyy'}
                                                                                maxDate={new Date()}
                                                                                calendarIconClassname='tex-end'
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                                yearDropdownItemNumber={40}
                                                                                showMonthDropdown
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_three_life_insurance.${index}.term_amount`)}
                                                                    placeholder="Term Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionThreeLifeInsuranceFields];
                                                                        newFields[index].term_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalInsuranceTerm(sectionThreeLifeInsuranceFields.reduce((acc: number, item: any) => acc + (parseFloat(item.term_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_three_life_insurance.${index}.whole_life_amount`)}
                                                                    placeholder="Whole Life Amount"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionThreeLifeInsuranceFields];
                                                                        newFields[index].whole_life_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalInsuranceWhole(sectionThreeLifeInsuranceFields.reduce((acc: number, item: any) => acc + (parseFloat(item.whole_life_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_three_life_insurance.${index}.loan_amount`)}
                                                                    placeholder="Loan Amount"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionThreeLifeInsuranceFields];
                                                                        newFields[index].loan_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalInsuranceLoan(sectionThreeLifeInsuranceFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loan_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionThreeLifeInsurance({ insurance_company_name: '', insured_person_name: '', beneficiary_name: '', term_date: '', term_amount: 0, whole_life_amount: 0, loan_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={9}>Total</td>
                                                        <td colSpan={1}>${parseFloat('' + totalInsuranceTerm).toFixed(2)}</td>
                                                        <td colSpan={1}>${parseFloat('' + totalInsuranceWhole).toFixed(2)}</td>
                                                        <td colSpan={1}>${parseFloat('' + totalInsuranceLoan).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 4 / RECEIVABLES DUE ON MORTGAGES AND CONTRACTS OR LOANS
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={3}>Name of Debtor</td>
                                                        <td colSpan={3}>Description/Property/Contract/Loan/Individual Name</td>
                                                        <td colSpan={2}>Due Date</td>
                                                        <td colSpan={2}>Repayment Terms</td>
                                                        <td colSpan={2}>Balance (in $)</td>
                                                    </tr>
                                                    {sectionFourReceivableDueFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_four_receivable_due.${index}.debtor_name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_four_receivable_due.${index}.description`)}
                                                                    placeholder="Description"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <Controller
                                                                    name={`personal_balance_sheet_section_four_receivable_due.${index}.due_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <DatePicker
                                                                                key={8}
                                                                                selected={field.value ? new Date(field.value) : new Date()}
                                                                                onChange={(e: any) => {
                                                                                    field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                                }}
                                                                                className=''
                                                                                dateFormat={'MM/dd/yyyy'}
                                                                                maxDate={new Date()}
                                                                                calendarIconClassname='tex-end'
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                                yearDropdownItemNumber={40}
                                                                                showMonthDropdown
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_four_receivable_due.${index}.repayment_terms`)}
                                                                    placeholder="terms"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_four_receivable_due.${index}.balance_amount`)}
                                                                    placeholder="Balance"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionFourReceivableDueFields];
                                                                        newFields[index].balance_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalReceivableDue(sectionFourReceivableDueFields.reduce((acc: number, item: any) => acc + (parseFloat(item.balance_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionFourReceivableDue({ debtor_name: '', description: '', due_date: '', repayment_terms: '', balance_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalReceivableDue).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 5 / REAL ESTATE OWNED- List Primary Residence First
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={4}>Primary Property Address</td>
                                                        <td colSpan={1}>Yr. Built</td>
                                                        <td colSpan={1}>TREM</td>
                                                        <td colSpan={1}>Date Due</td>
                                                        <td colSpan={1}>Mo. Pmt (in $)</td>
                                                        <td colSpan={1}>Purchase (in $)</td>
                                                        <td colSpan={1}>Mrkt Value (in $)</td>
                                                        <td colSpan={2}>Mortgage Bal. (in $)</td>
                                                    </tr>
                                                    {sectionFiveRealEstatePrimaryPropertyFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.address`)}
                                                                    placeholder="Address"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.build_date`)}
                                                                    placeholder="Year Built"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.term`)}
                                                                    placeholder="Term"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`personal_balance_sheet_section_five_real_estate_primary_property.${index}.due_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <DatePicker
                                                                                key={9}
                                                                                selected={field.value ? new Date(field.value) : new Date()}
                                                                                onChange={(e: any) => {
                                                                                    field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                                }}
                                                                                className=''
                                                                                dateFormat={'MM/dd/yyyy'}
                                                                                maxDate={new Date()}
                                                                                calendarIconClassname='tex-end'
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                                yearDropdownItemNumber={40}
                                                                                showMonthDropdown
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.monthly_payment_amount`)}
                                                                    placeholder="Monthly Payment"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.purchase_amount`)}
                                                                    placeholder="Purchase Price"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.market_value_amount`)}
                                                                    placeholder="Market Value"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionFiveRealEstatePrimaryPropertyFields];
                                                                        newFields[index].market_value_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalRealEstatePrimaryMrkt(sectionFiveRealEstatePrimaryPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.market_value_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_primary_property.${index}.mortgage_balance_amount`)}
                                                                    placeholder="Mortgage Balance"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionFiveRealEstatePrimaryPropertyFields];
                                                                        newFields[index].mortgage_balance_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalRealEstatePrimaryMortgage(sectionFiveRealEstatePrimaryPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.mortgage_balance_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionFiveRealEstatePrimaryProperty({ address: '', build_date: '', term: '', due_date: '', monthly_payment_amount: 0, purchase_amount: 0, market_value_amount: 0, mortgage_balance_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={9}>Total Primary</td>
                                                        <td colSpan={1}>${parseFloat('' + totalRealEstatePrimaryMrkt).toFixed(2)}</td>
                                                        <td colSpan={2}>${parseFloat('' + totalRealEstatePrimaryMortgage).toFixed(2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4}>Other Property Address</td>
                                                        <td colSpan={1}>Yr. Built</td>
                                                        <td colSpan={1}>TREM</td>
                                                        <td colSpan={1}>Date Due</td>
                                                        <td colSpan={1}>Mo. Pmt (in $)</td>
                                                        <td colSpan={1}>Purchase (in $)</td>
                                                        <td colSpan={1}>Mrkt Value (in $)</td>
                                                        <td colSpan={2}>Mortgage Bal. (in $)</td>
                                                    </tr>
                                                    {sectionFiveRealEstateOtherPropertyFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.address`)}
                                                                    placeholder="Address"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.build_date`)}
                                                                    placeholder="Year Built"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.term`)}
                                                                    placeholder="Term"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`personal_balance_sheet_section_five_real_estate_other_property.${index}.due_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <DatePicker
                                                                                key={10}
                                                                                selected={field.value ? new Date(field.value) : new Date()}
                                                                                onChange={(e: any) => {
                                                                                    field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                                }}
                                                                                className=''
                                                                                dateFormat={'MM/dd/yyyy'}
                                                                                maxDate={new Date()}
                                                                                calendarIconClassname='tex-end'
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                                yearDropdownItemNumber={40}
                                                                                showMonthDropdown
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.monthly_payment_amount`)}
                                                                    placeholder="Monthly Payment"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.purchase_amount`)}
                                                                    placeholder="Purchase Price"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.market_value_amount`)}
                                                                    placeholder="Market Value"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionFiveRealEstateOtherPropertyFields];
                                                                        newFields[index].market_value_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalRealEstateOtherMrkt(sectionFiveRealEstateOtherPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.market_value_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`personal_balance_sheet_section_five_real_estate_other_property.${index}.mortgage_balance_amount`)}
                                                                    placeholder="Mortgage Balance"
                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionFiveRealEstateOtherPropertyFields];
                                                                        newFields[index].mortgage_balance_amount = parseFloat(event.target.value) || 0;
                                                                        setTotalRealEstateOtherMortgage(sectionFiveRealEstateOtherPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.mortgage_balance_amount) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionFiveRealEstateOtherProperty({ address: '', build_date: 0, term: '', due_date: '', monthly_payment_amount: 0, purchase_amount: 0, market_value_amount: 0, mortgage_balance_amount: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={9}>Total Primary</td>
                                                        <td colSpan={1}>${parseFloat('' + totalRealEstateOtherMrkt).toFixed(2)}</td>
                                                        <td colSpan={2}>${parseFloat('' + totalRealEstateOtherMortgage).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 6 / PROFIT SHARING AND PENSION
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={3}>Name of Institution</td>
                                                        <td colSpan={3}>Account Type</td>
                                                        <td colSpan={2}>Value (in $)</td>
                                                        <td colSpan={2}>Vested (in $)</td>
                                                        <td colSpan={2}>Loans (in $)</td>
                                                    </tr>
                                                    {sectionSixProfitSharingFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_six_profit_sharing.${index}.name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_six_profit_sharing.${index}.account_type`)}
                                                                    placeholder="Account Type"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_six_profit_sharing.${index}.value`)}
                                                                    placeholder="Value"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionSixProfitSharingFields];
                                                                        newFields[index].value = parseFloat(event.target.value) || 0;
                                                                        setTotalPensionValue(sectionSixProfitSharingFields.reduce((acc: number, item: any) => acc + (parseFloat(item.value) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_six_profit_sharing.${index}.vested`)}
                                                                    placeholder="Vested"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionSixProfitSharingFields];
                                                                        newFields[index].vested = parseFloat(event.target.value) || 0;
                                                                        setTotalPensionVested(sectionSixProfitSharingFields.reduce((acc: number, item: any) => acc + (parseFloat(item.vested) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_six_profit_sharing.${index}.loans`)}
                                                                    placeholder="Loans"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionSixProfitSharingFields];
                                                                        newFields[index].loans = parseFloat(event.target.value) || 0;
                                                                        setTotalPensionLoan(sectionSixProfitSharingFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loans) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionSixProfitSharing({ name: '', account_type: '', value: 0, vested: 0, loans: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={6}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalPensionValue).toFixed(2)}</td>
                                                        <td colSpan={2}>${parseFloat('' + totalPensionVested).toFixed(2)}</td>
                                                        <td colSpan={2}>${parseFloat('' + totalPensionLoan).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                {/* </div>
                                    <div ref={ref => pagesRefs.current[2] = ref}> */}
                                <table className="personal-balance-sheet main-table" ref={ref => pagesRefs.current[3] = ref}>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 7 / AUTOMOBILES
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={1}>Year</td>
                                                        <td colSpan={2}>Make</td>
                                                        <td colSpan={3}>Model</td>
                                                        <td colSpan={2}>Miles</td>
                                                        <td colSpan={2}>Loan Balance (in $)</td>
                                                        <td colSpan={1}>Appraisal (in $)</td>
                                                        <td colSpan={1}>Value (in $)</td>
                                                    </tr>
                                                    {sectionSevenAutomobilesFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_seven_automobiles.${index}.year`)}
                                                                    placeholder="Year"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_seven_automobiles.${index}.make`)}
                                                                    placeholder="Make"
                                                                />
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_seven_automobiles.${index}.model`)}
                                                                    placeholder="Model"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_seven_automobiles.${index}.miles`)}
                                                                    placeholder="Miles"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_seven_automobiles.${index}.loan_balance`)}
                                                                    placeholder="Loan Balance"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionSevenAutomobilesFields];
                                                                        newFields[index].loan_balance = parseFloat(event.target.value) || 0;
                                                                        setTotalAutoMobileLoan(sectionSevenAutomobilesFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loan_balance) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_seven_automobiles.${index}.apprisal`)}
                                                                    placeholder="Appraisal"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionSevenAutomobilesFields];
                                                                        newFields[index].apprisal = parseFloat(event.target.value) || 0;
                                                                        setTotalAutoMobileAppraisal(sectionSevenAutomobilesFields.reduce((acc: number, item: any) => acc + (parseFloat(item.apprisal) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_seven_automobiles.${index}.value`)}
                                                                    placeholder="Value"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionSevenAutomobilesFields];
                                                                        newFields[index].value = parseFloat(event.target.value) || 0;
                                                                        setTotalAutoMobileValue(sectionSevenAutomobilesFields.reduce((acc: number, item: any) => acc + (parseFloat(item.value) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionSevenAutomobiles({ year: 0, make: '', model: '', miles: '', loan_balance: 0, apprisal: 0, value: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={8}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalAutoMobileLoan).toFixed(2)}</td>
                                                        <td colSpan={1}>${parseFloat('' + totalAutoMobileAppraisal).toFixed(2)}</td>
                                                        <td colSpan={1}>${parseFloat('' + totalAutoMobileValue).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 8 / PERSONAL PROPERTY
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={4}>Description</td>
                                                        <td colSpan={1}>Insured Y/N</td>
                                                        <td colSpan={2}>Agent Name</td>
                                                        <td colSpan={2}>Phone</td>
                                                        <td colSpan={1}>Appraisal (in $)</td>
                                                        <td colSpan={1}>Loans (in $)</td>
                                                        <td colSpan={1}>Value (in $)</td>
                                                    </tr>
                                                    {sectionEightPersonalPropertyFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={4}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_eight_personal_property.${index}.description`)}
                                                                    placeholder="Description"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_eight_personal_property.${index}.insured`)}
                                                                    placeholder="Insured"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_eight_personal_property.${index}.agent`)}
                                                                    placeholder="Agent"
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_eight_personal_property.${index}.phone`)}
                                                                    placeholder="Phone"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_eight_personal_property.${index}.apprisal`)}
                                                                    placeholder="Appraisal"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionEightPersonalPropertyFields];
                                                                        newFields[index].apprisal = parseFloat(event.target.value) || 0;
                                                                        setTotalPropertyAppraisal(sectionEightPersonalPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.apprisal) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_eight_personal_property.${index}.loans`)}
                                                                    placeholder="Loans"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionEightPersonalPropertyFields];
                                                                        newFields[index].loans = parseFloat(event.target.value) || 0;
                                                                        setTotalPropertyLoan(sectionEightPersonalPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.loans) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_eight_personal_property.${index}.value`)}
                                                                    placeholder="Value"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionEightPersonalPropertyFields];
                                                                        newFields[index].value = parseFloat(event.target.value) || 0;
                                                                        setTotalPropertyValue(sectionEightPersonalPropertyFields.reduce((acc: number, item: any) => acc + (parseFloat(item.value) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionEightPersonalProperty({ description: '', insured: '', agent: '', phone: '', apprisal: 0, loans: 0, value: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={9}>Total</td>
                                                        <td colSpan={1}>${parseFloat('' + totalPropertyAppraisal).toFixed(2)}</td>
                                                        <td colSpan={1}>${parseFloat('' + totalPropertyLoan).toFixed(2)}</td>
                                                        <td colSpan={1}>${parseFloat('' + totalPropertyValue).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 9 /  SHORT TER NOTES DUE FINANCIAL INSTITUTION
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={7}>Name of Creditor / Type of Credit Account</td>
                                                        <td colSpan={1}>Date Borrowed</td>
                                                        <td colSpan={1}>Amount Borrowed (in $)</td>
                                                        <td colSpan={1}>Due Date</td>
                                                        <td colSpan={1}>Payment/Mo (in $)</td>
                                                        <td colSpan={1}>Balance (in $)</td>
                                                    </tr>
                                                    {sectionNineNotesFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={7}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_nine_notes.${index}.name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`section_nine_notes.${index}.date_borrowed`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <DatePicker
                                                                                key={11}
                                                                                selected={field.value ? new Date(field.value) : new Date()}
                                                                                onChange={(e: any) => {
                                                                                    field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                                }}
                                                                                className=''
                                                                                dateFormat={'MM/dd/yyyy'}
                                                                                maxDate={new Date()}
                                                                                calendarIconClassname='tex-end'
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                                yearDropdownItemNumber={40}
                                                                                showMonthDropdown
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='nunber'
                                                                    {...register(`section_nine_notes.${index}.amount_borrowed`)}
                                                                    placeholder="Amount Borrowed"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`section_nine_notes.${index}.due_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <DatePicker
                                                                                key={12}
                                                                                selected={field.value ? new Date(field.value) : new Date()}
                                                                                onChange={(e: any) => {
                                                                                    field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                                }}
                                                                                className=''
                                                                                dateFormat={'MM/dd/yyyy'}
                                                                                maxDate={new Date()}
                                                                                calendarIconClassname='tex-end'
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                                yearDropdownItemNumber={40}
                                                                                showMonthDropdown
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_nine_notes.${index}.payment`)}
                                                                    placeholder="Payment"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_nine_notes.${index}.balance`)}
                                                                    placeholder="Balance"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionNineNotesFields];
                                                                        newFields[index].balance = parseFloat(event.target.value) || 0;
                                                                        setTotalFinancial(sectionNineNotesFields.reduce((acc: number, item: any) => acc + (parseFloat(item.balance) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionNineNotes({ name: '', date_borrowed: '', amount_borrowed: 0, due_date: '', payment: 0, balance: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={11}>Total</td>
                                                        <td colSpan={1}>${parseFloat('' + totalFinancial).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Other Short Term Liabilities/Notes Due (Section 9 Continued)
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={7}>Name of Creditor / Type of Credit Account</td>
                                                        <td colSpan={1}>Date Borrowed</td>
                                                        <td colSpan={1}>Amount Borrowed (in $)</td>
                                                        <td colSpan={1}>Due Date</td>
                                                        <td colSpan={1}>Payment/Mo (in $)</td>
                                                        <td colSpan={1}>Balance (in $)</td>
                                                    </tr>
                                                    {sectionNineOtherNotesFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={7}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_nine_other_notes.${index}.name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`section_nine_other_notes.${index}.date_borrowed`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            key={13}
                                                                            selected={field.value ? new Date(field.value) : new Date()}
                                                                            onChange={(e: any) => {
                                                                                field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                            }}
                                                                            className=''
                                                                            dateFormat={'MM/dd/yyyy'}
                                                                            maxDate={new Date()}
                                                                            calendarIconClassname='tex-end'
                                                                            showYearDropdown
                                                                            scrollableYearDropdown
                                                                            yearDropdownItemNumber={40}
                                                                            showMonthDropdown
                                                                        />
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_nine_other_notes.${index}.amount_borrowed`)}
                                                                    placeholder="Amount Borrowed"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`section_nine_other_notes.${index}.due_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            key={14}
                                                                            selected={field.value ? new Date(field.value) : new Date()}
                                                                            onChange={(e: any) => {
                                                                                field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                            }}
                                                                            className=''
                                                                            dateFormat={'MM/dd/yyyy'}
                                                                            maxDate={new Date()}
                                                                            calendarIconClassname='tex-end'
                                                                            showYearDropdown
                                                                            scrollableYearDropdown
                                                                            yearDropdownItemNumber={40}
                                                                            showMonthDropdown
                                                                        />
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_nine_other_notes.${index}.payment`)}
                                                                    placeholder="Payment"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_nine_other_notes.${index}.balance`)}
                                                                    placeholder="Balance"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionNineOtherNotesFields];
                                                                        newFields[index].balance = parseFloat(event.target.value) || 0;
                                                                        setTotalLiabilities(sectionNineOtherNotesFields.reduce((acc: number, item: any) => acc + (parseFloat(item.balance) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionNineOtherNotes({ name: '', date_borrowed: '', amount_borrowed: 0, due_date: 0, payment: 0, balance: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={11}>Total</td>
                                                        <td colSpan={1}>${parseFloat('' + totalLiabilities).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div className="collapsible-header" onClick={() => { }}>
                                                Section 10 / CREDIT ACCOUNTS, BILLS DUE, ALIMONY/CHILD SUPPORT, DAYCARE, etc.
                                            </div>
                                            <div className="collapsible-content" id="collapsible-section-1">
                                                <table className="section-table">
                                                    <tr>
                                                        <td colSpan={7}>Name Of Creditor/ Payments To:</td>
                                                        <td colSpan={1}>High Balance (in $)</td>
                                                        <td colSpan={1}>Start Date</td>
                                                        <td colSpan={1}>End Date</td>
                                                        <td colSpan={2}>Monthly Payment (in $)</td>
                                                    </tr>
                                                    {sectionTenFields.map((item: any, index: number) => (
                                                        <tr key={item.id}>
                                                            <td colSpan={7}>
                                                                <input
                                                                    type='text'
                                                                    {...register(`section_ten.${index}.name`)}
                                                                    placeholder="Name"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_ten.${index}.high_balance`)}
                                                                    placeholder="High Balance"
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`section_ten.${index}.start_date`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            key={15}
                                                                            selected={field.value ? new Date(field.value) : new Date()}
                                                                            onChange={(e: any) => {
                                                                                field.onChange(moment(e).format('MM/DD/YYYY'));
                                                                            }}
                                                                            className=''
                                                                            dateFormat={'MM/dd/yyyy'}
                                                                            maxDate={new Date()}
                                                                            calendarIconClassname='tex-end'
                                                                            showYearDropdown
                                                                            scrollableYearDropdown
                                                                            yearDropdownItemNumber={40}
                                                                            showMonthDropdown
                                                                        />
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={1}>
                                                                <Controller
                                                                    name={`section_ten.${index}.end_date`}
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <DatePicker
                                                                            key={16}
                                                                            selected={value ? new Date(value) : new Date()}
                                                                            onChange={(e: any) => {
                                                                                onChange(moment(e).format('MM/DD/YYYY'));
                                                                            }}
                                                                            className=''
                                                                            dateFormat={'MM/dd/yyyy'}
                                                                            maxDate={new Date()}
                                                                            calendarIconClassname='tex-end'
                                                                            showYearDropdown
                                                                            scrollableYearDropdown
                                                                            yearDropdownItemNumber={40}
                                                                            showMonthDropdown
                                                                        />
                                                                    )}
                                                                />
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input
                                                                    type='number'
                                                                    {...register(`section_ten.${index}.payment`)}
                                                                    placeholder="Payment"

                                                                    onChange={(event) => {
                                                                        const newFields = [...sectionTenFields];
                                                                        newFields[index].payment = parseFloat(event.target.value) || 0;
                                                                        setTotalSectionTen(sectionTenFields.reduce((acc: number, item: any) => acc + (parseFloat(item.payment) || 0), 0));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {
                                                        !isEdit && (
                                                            <tr>
                                                        <td colSpan={12}>
                                                            <button type="button" className='button' onClick={() => addMoreSectionTen({ name: '', high_balance: 0, start_date: '', end_date: '', payment: 0 })}>Add Row</button>
                                                        </td>
                                                    </tr>
                                                        )
                                                    }
                                                    
                                                    <tr className="total-row">
                                                        <td colSpan={10}>Total</td>
                                                        <td colSpan={2}>${parseFloat('' + totalSectionTen).toFixed(2)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* </div> */}
                                </table>
                            </div>
                            <button type="button" onClick={submitForm} className="btn btn-secondary mt-4">
                                {showLoader && (<Spinner animation="border" size="sm" />) || 'Save and Generate PDF'}
                            </button>
                        </Form>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default PersonalBalanceSheet;